import React from 'react'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Menu from './Pages/Menu/Menu'
import Gallery from './Pages/Gallery/Gallery'
import Specials from './Pages/Specials/Specials'
import Reviews from './Pages/Reviews/Reviews'
import Contact from './Pages/Contact/Contact'
import OrderNow from './Pages/OrderNow/OrderNow'
import Footer from './Components/Footer/Footer'
import Navbar from './Components/Navbar/Navbar'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './Components/ScrollTop/ScrollTop'

const App = () => {
  return (
    <>
    <ToastContainer/>
      <div className='app'>
        <ScrollToTop />
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/menu' element={<Menu />}/>
          <Route path='/gallery' element={<Gallery />}/>
          <Route path='/specials' element={<Specials />}/>
          <Route path='/reviews' element={<Reviews />}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/order' element={<OrderNow/>}/>
        </Routes>
      </div>
      <Footer />
    </>
  )
}

export default App
