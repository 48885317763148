import React from 'react'
import './PageBanner.css'

const PageBanner = (props) => {
  return (
    <div className="headerBar">
        <h1>{props.pagename}</h1>
      </div>
  )
}

export default PageBanner