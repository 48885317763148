import React, { useState } from 'react'
import './Menu.css'
import Img1 from '../../Images/pcafe6.jpg'
import Img2 from '../../Images/pcafe7.jpg'
import Img3 from '../../Images/meduVada.jpg'
import Img4 from '../../Images/rava.jpg'
import Img5 from '../../Images/riceCombo.jpg'
import Img6 from '../../Images/lemonRice.jpg'
import Img7 from '../../Images/coconutCurry.jpg'
import Img8 from '../../Images/curd-rice-dahi-bhat-chawal-with.jpg'
import Img9 from '../../Images/coconutStew.jpg'
import Img10 from '../../Images/img10.jpg'
import Img11 from '../../Images/img11.jpg'
import Img12 from '../../Images/img12.jpg'
import undi from '../../Images/undi.png'
import PageBanner from '../../Components/PageBanner/PageBanner'

function Breakfast() {
  return (
    <>
      <div className="menuBox">
        <img src={Img1} alt="" />
        <div className="menuBoxcontent">
          <h3>Idli Sambar</h3>
          <p>Steamed rice cakes with tangy lentils.</p>
          <h4>Rs.99</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img2} alt="" />
        <div className="menuBoxcontent">
          <h3>Podi Dosa</h3>
          <p>Crispy dosa with spicy podi powder.</p>
          <h4>Rs.129</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img3} alt="" />
        <div className="menuBoxcontent">
          <h3>Medu Vada</h3>
          <p>Fried lentil donuts with coconut chutney.</p>
          <h4>Rs.80</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img4} alt="" />
        <div className="menuBoxcontent">
          <h3>Rava Upma</h3>
          <p>Delicious Rava and Upma with Chutney</p>
          <h4>Rs.110</h4>
        </div>
      </div>
    </>
  );
}


function Lunch() {
  return (
    <>
      <div className="menuBox">
        <img src={Img5} alt="" />
        <div className="menuBoxcontent">
          <h3>Rice Combo</h3>
          <p>Spiced rice and lentil stew with sambhar</p>
          <h4>Rs.200</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img6} alt="" />
        <div className="menuBoxcontent">
          <h3>Lemon Rice</h3>
          <p>Zesty rice tempered with mustard seeds.</p>
          <h4>Rs.220</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img7} alt="" />
        <div className="menuBoxcontent">
          <h3>Coconut Curry</h3>
          <p>Rich coconut-based curry with vegetables.</p>
          <h4>Rs.250</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img8} alt="" />
        <div className="menuBoxcontent">
          <h3>Curd Rice</h3>
          <p>Cool yogurt rice with tempered spices.</p>
          <h4>Rs.299</h4>
        </div>
      </div>

    </>
  );
}


function Dinner() {
  return (
    <>
      <div className="menuBox">
        <img src={Img9} alt="" />
        <div className="menuBoxcontent">
          <h3>Appam Stew</h3>
          <p>Soft pancakes with coconut vegetable stew.</p>
          <h4>Rs.300</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img10} alt="" />
        <div className="menuBoxcontent">
          <h3>Ghee Roast</h3>
          <p>Crispy dosa with clarified butter drizzle.</p>
          <h4>Rs.320</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img11} alt="" />
        <div className="menuBoxcontent">
          <h3>Puliyogare Rice</h3>
          <p>Tamarind-flavored rice with roasted peanuts.</p>
          <h4>Rs.350</h4>
        </div>
      </div>
      <div className="menuBox">
        <img src={Img12} alt="" />
        <div className="menuBoxcontent">
          <h3>Kothu Parotta</h3>
          <p>Shredded flatbread stir-fried with spices.</p>
          <h4>Rs.399</h4>
        </div>
      </div>
    </>
  );
}

function Menu() {

  const [activeComponent, setActiveComponent] = useState('Breakfast');

  const showComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <PageBanner pagename={"Menu"} />
      <div className="tabs">
        <div className="undi1">
          <img src={undi} alt="" />
        </div>
        <button className={activeComponent === 'Breakfast' ? 'button active' : 'button'} onClick={() => showComponent('Breakfast')}>Breakfast</button>
        <button className={activeComponent === 'Lunch' ? 'button active' : 'button'} onClick={() => showComponent('Lunch')}>Lunch</button>
        <button className={activeComponent === 'Dinner' ? 'button active' : 'button'} onClick={() => showComponent('Dinner')}>Dinner</button>
        <div className="undi1">
          <img src={undi} alt="" />
        </div>
      </div>
      <div className="menuContainer">
        {activeComponent === 'Breakfast' && <Breakfast />}
        {activeComponent === 'Lunch' && <Lunch />}
        {activeComponent === 'Dinner' && <Dinner />}
      </div>
    </>
  )
}

export default Menu
