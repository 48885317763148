import React, { useState } from 'react'
import './Navbar.css'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import Logo from '../../Images/Logo.jpg'
import undi from '../../Images/undi.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';



const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="option">
        <ul>
          <li className='addToggle'><Link className='link-style'>
            <i className="fa-solid fa-phone"></i>
            <p>+91 74047 94047</p>
          </Link></li>
          <li className='addToggle'><Link className='link-style'>
            <i className="fa-solid fa-envelope"></i>
            <p>info@pillaiscafe.in</p>
          </Link></li>
          <li ><Link className='link-style'>
            <i className="fa-solid fa-location-dot"></i>
            <p>Shop no. 13, Balaji Heritage, plot -83, Block G, Sector 11, Kharghar, Navi Mumbai, Maharashtra 410210</p>
          </Link></li>
        </ul>
      </div>
      <header className="header">
        <div className={isOpen ? "mobileMenu open" : "close"}>
          <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
          <li><NavLink to="/about" className="navlink" onClick={toggleMenu}>About Us</NavLink></li>
          <li><NavLink to="/menu" className="navlink" onClick={toggleMenu}>Menu</NavLink></li>
          <li><NavLink to="/gallery" className="navlink" onClick={toggleMenu}>Gallery</NavLink></li>
          <li><NavLink to="/specials" className="navlink" onClick={toggleMenu}>Specials</NavLink></li>
          <li><NavLink to="/reviews" className="navlink" onClick={toggleMenu}>Reviews</NavLink></li>
          <li><NavLink to="/order" className="navlink" onClick={toggleMenu}>Order Now</NavLink></li>
          <li><NavLink to="/contact" className="navlink" onClick={toggleMenu}>Contact Us</NavLink></li>
          
        </div>
        <div className="logo">
          <img className="logoI" src={Logo} alt="" />
          <img className="logoU" src={undi} alt="" />
        </div>
        <div className="navbar">
          <ul>
            <li><NavLink to="/" className="navlink">Home</NavLink></li>
            <li><NavLink to="/about" className="navlink">About Us</NavLink></li>
            <li><NavLink to="/menu" className="navlink">Menu</NavLink></li>
            <li><NavLink to="/gallery" className="navlink">Gallery</NavLink></li>
            <li><NavLink to="/specials" className="navlink">Specials</NavLink></li>
            <li><NavLink to="/reviews" className="navlink">Reviews</NavLink></li>
            <li><NavLink to="/contact" className="navlink">Contact Us</NavLink></li>
          </ul>
        </div>
        <div className="book">
          <div className="navbar-right">
          <button><NavLink to="/order" className="navlink">Order Now</NavLink></button>
            <div className="menu-icon" onClick={toggleMenu}>
              <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
            </div>
            
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar
