import React, { useState } from 'react'
import Img1 from '../../Images/menu/1.jpg'
import Img2 from '../../Images/menu/2.jpg'
import Img3 from '../../Images/menu/3.jpg'
import Img4 from '../../Images/menu/4.jpg'
import Img5 from '../../Images/menu/5.jpg'
import Img6 from '../../Images/menu/6.jpg'
import Img7 from '../../Images/menu/7.jpg'
import Img8 from '../../Images/menu/8.jpg'
import Img9 from '../../Images/menu/9.jpg'
import Img10 from '../../Images/menu/10.jpg'
import './Gallery.css'
import PageBanner from '../../Components/PageBanner/PageBanner'

function Gallery() {

  return (
    <>
      <PageBanner pagename={"Gallery"} />
      <div className="galleyMenu">
        <div className='gallery'>
          <div className="box row-2" id='box1'></div>
          <div className="box col-2" id='box2'></div>
          <div className="box" id='box3'></div>
          <div className="box row-2" id='box4'></div>
          <div className="box col-2" id='box5'></div>
          <div className="box" id='box6'></div>
          <div className="box col-2" id='box7'></div>
          <div className="box row-2" id='box8'></div>
          <div className="box col-2" id='box9'></div>
          <div className="box col-2" id='box10'></div>
          <div className="box col-2" id='box11'></div>
          <div className="box" id='box12'></div>
        </div>
      </div>

    </>
  )
}

export default Gallery
