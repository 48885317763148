import React from 'react'
import './Footer.css'
import Logo from '../../Images/Logo.jpg'
import { Link, NavLink } from 'react-router-dom'

function Footer() {
  return (
    <footer className='footer'>
      <div className="footer-container1">
        <div className="footer-container1-box1">
          <i class="fa-solid fa-location-dot"></i>
          <div className="address">
            <h3>Find Us</h3>
            <p>Sector 13, Kharghar</p>
          </div>
        </div>
        <div className="footer-container1-box2">
          <i class="fa-solid fa-phone"></i>
          <div className="phone">
            <h3>Call Us</h3>
            <p>+91 99999 88888</p>
          </div>
        </div>
        <div className="footer-container1-box3">
          <i class="fa-solid fa-envelope"></i>
          <div className="mail">
            <h3>Mail Us</h3>
            <p>pillaiscafe@gmail.com</p>
          </div>
        </div>
      </div>
      <div className="footer-container2">
        <div className="footer-container2-box1">
          <img src={Logo} alt="" />
          <h3>Follow Us</h3>
          <div className="social-icons">
          <Link to="https://www.facebook.com/Pillais.cafe31" target='mib' className="navlink" ><i class="fa-brands fa-facebook"></i></Link>
          <Link to="https://www.instagram.com/pillais.cafe/" target='mib' className="navlink" ><i class="fa-brands fa-instagram"></i></Link>
          <Link to="https://x.com/CafePillai" target='mib' className="navlink" ><i class="fa-brands fa-twitter"></i></Link>
          </div>
        </div>
        <div className="footer-container2-box2">
          <h3>Quick Links</h3>

          <div className="links">
            <ul >
              <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
              <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
              <li><NavLink to="/menu" className={() => `navlink`}>Menu</NavLink></li>
              <li><NavLink to="/gallery" className={() => `navlink`}>Gallery</NavLink></li>
              <li><NavLink to="/specials" className={() => `navlink`}>Specials</NavLink></li>
              <li><NavLink to="/reviews" className={() => `navlink`}>Reviews</NavLink></li>
              <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
            </ul>
          </div>
        </div>
        <div className="footer-container2-box3">
          <h3>Reach Us</h3>
          <div className='mapBox'>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.519522181684!2d73.06941857520448!3d19.04088258215646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c347a4791f0d%3A0x4d4c50e8336ed8d3!2sPillai&#39;s%20Cafe%20Bir-yummy!5e0!3m2!1sen!2sin!4v1713426947220!5m2!1sen!2sin" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className="footer-container3">
        <div className="footer-container3-box1">
          <h3>&copy; Pillai's Cafe 2024 | All Rights Reserved
          </h3>
        </div>
        <div className="footer-container3-box2">
          <h3>Designed By Himali Ajay Chavan</h3>
        </div>
      </div>
    </footer>
  )
}

export default Footer
