import React from 'react'
import './About.css'
import bg from '../../Images/bgImg.jpg'
import undi from '../../Images/undi.png'
import first from '../../Images/bulkParty.png'
import second from '../../Images/fineDine.png'
import chef1 from '../../Images/Chef1.jpg'
import chef2 from '../../Images/Chef2.jpg'
import chef3 from '../../Images/Chef3.jpg'
import PageBanner from '../../Components/PageBanner/PageBanner'



function About() {
  return (
    <>
     <PageBanner pagename={"About Us"} />
      <div className="aboutContainer">
        <div className="aboutImg">
          <img src={bg} alt="" />
        </div>
        <div className="info">
          <h3>Our Story</h3>
          <h1>About Pillai's Cafe</h1>
          <img src={undi} alt="" />
          <p>Pillai's Cafe stands as a beacon of culinary excellence in Kharghar, offering a unique fusion of South Indian and Chinese cuisine. Our journey began with a passion for authentic flavors and a commitment to providing a memorable dining experience. Nestled in the vibrant heart of Kharghar, our cozy cafe welcomes guests to embark on a gastronomic adventure where tradition meets innovation. 
          </p>
          <div className="points">
            <div className="first">
              <img src={first} alt="" />
              <div>
                <h1>Delicious Delights</h1>
                <p>Savor a diverse range of South Indian and Chinese specialties.
                </p>
              </div>
            </div>
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Cozy Ambiance</h1>
                <p>Relax in our inviting space designed for comfort and enjoyment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chefsContainer">
        <h1>Our Chefs</h1>
        <img src={undi} alt="" />
        <div className="chefs">
          <div className="chef">
            <img src={chef1} alt="" />
            <div>
              <h1>Chef SH</h1>
              <p>With a passion for creativity, Chef Ananya blends tradition with innovation.
              </p>
            </div>
          </div>
          <div className="chef">
            <img src={chef2} alt="" />
            <div>
              <h1>Chef Himali</h1>
              <p>Renowned for his culinary expertise, Chef Vikram elevates every dish with finesse.
              </p>
            </div>
          </div>
          <div className="chef">
            <img src={chef3} alt="" />
            <div>
              <h1>Chef Tejaswini</h1>
              <p>LInfusing flavors with love, Chef Priya creates memorable gastronomic experiences.
              </p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default About
