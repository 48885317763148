import React, { useState } from 'react'
import './Contact.css'
import undi from '../../Images/undi.png'
import contactImg from '../../Images/contactImg.jpeg'
import PageBanner from '../../Components/PageBanner/PageBanner'

function Contact() {
  return (
    <>
      <PageBanner pagename={"Contact Us"} />
      <div className="contactContainer">
        <div className="contactBox1">
          <img src={undi} alt="" />
          <h2>Our Location</h2>
          <p>Shop no. 13, Balaji Heritage, Sector 11, Kharghar, Navi Mumbai, 410210</p>
        </div>
        <div className="contactBox2">
          <img src={undi} alt="" />
          <h2>Our Contact</h2>
          <ul>
            <li>+91 74047 94047</li>
            <li>+91 98332 75472</li>
          </ul>
        </div>
        <div className="contactBox3">
          <img src={undi} alt="" />
          <h2>Mail Us</h2>
          <p>info@pillaiscafe.in</p>
          <p>contact@pillaiscafe.in</p>
        </div>
      </div>
      <div className="contactForm">
        <div className="formSection1">
          <img src={contactImg} alt="" />
        </div>
        <div className="formSection2">
          <h1>Get In Touch</h1>
          <img src={undi} alt="" />
          <p>Fill out the form below to get in touch with the team of our Pillai's Cafe</p>
  
          <form>
            <input type="text" placeholder='Your Name' 
               />
            <input type="tel" placeholder='Your Phone' 
              />
            <input type="email" placeholder='Email' 
              />
            <textarea type="text" placeholder='Message'
            />
            <button type="submit" className='formButton'>Send Message</button>
          </form>
        </div>
      </div>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30172.156174210508!2d73.0338846743164!3d19.0408826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c347a4791f0d%3A0x4d4c50e8336ed8d3!2sPillai&#39;s%20Cafe%20Bir-yummy!5e0!3m2!1sen!2sin!4v1712902242321!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='pillais cafe'></iframe>
      </div>
    </>
  )
}

export default Contact
