import React from 'react'
import './OrderNow.css'
import Swiggy from '../../Images/swiggy.jpg'
import Zomato from '../../Images/zomato.png'
import Logo from '../../Images/Logo.jpg'
import { Link } from 'react-router-dom'
import PageBanner from '../../Components/PageBanner/PageBanner'



function OrderNow() {
  return (
    <>
      <PageBanner pagename={"Order Now"} />

      <div className="order-container">
        <div className="party-orders">
          <img src={Logo} alt="" />
          <h2>
            We Accept Party Orders
          </h2>
        </div>
        <div className="order-now">
          <h2>Call Now To Order</h2>
          <h1>+91 74047 94047</h1>
          <h3>Or</h3>
          <h2>Order Online On</h2>
          <div className="online">
            <Link to="https://www.swiggy.com/city/mumbai/pillais-cafe-bir-yummy-lakhani-palm-view-nerul-rest755250" target='mib' className="navlink" ><img src={Swiggy} alt="" /></Link>
            <Link to="https://www.zomato.com/mumbai/pillais-cafe-1-kharghar-navi-mumbai" target='mib' className="navlink" ><img src={Zomato} alt="" /></Link>
          </div>
        </div>
      </div>



    </>
  )
}

export default OrderNow
