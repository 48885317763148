import React, { useState } from 'react'
import Logo from '../../Images/Logo.jpg'
import { Link } from 'react-router-dom'
import Knowmore1 from '../../Images/knowmore1.jpg'
import contactImg from '../../Images/contactImg.jpeg'
import undi from '../../Images/undi.png'
import Rice from '../../Images/riceCombo.jpg'
import MiniB from '../../Images/idliVada.jpeg'
import FullB from '../../Images/masalaDosa.jpg'
import './Home.css'
import { NavLink } from 'react-router-dom'

const Home = () => {

  const [category, setCategory] = useState("All")

  return (
    <>
      <main>
        <div className="container">
          <div className="box1">
            <h1>Pillai's Cafe</h1>
            <h2>Kharghar, Navi Mumbai</h2>
            <p>Pillai's Cafe is a delightful blend of South Indian and Chinese cuisines nestled in the heart of Kharghar. With a cozy ambiance and an extensive menu, we strive to provide a memorable dining experience for every customer.</p>
            <button>Know More</button>
          </div>
          <div className="box2">
            <img src={Logo} alt="Pillai Cafe Logo" />
          </div>
        </div>
      </main>
      <div className="knowMore">
        <div className="knowmore1">
          <img src={Knowmore1} alt="Know More" />
        </div>
        <div className="knowmore2">
          <h2>About Us...</h2>
          <p>Pillai's Cafe is more than just a restaurant; it's a culinary destination where passion meets innovation. Our story began with a vision to redefine the dining experience in Kharghar by seamlessly marrying the rich traditions of South Indian cuisine with the bold flavors of Chinese gastronomy. What started as a humble endeavor has blossomed into a beloved establishment cherished by locals and visitors alike.
            At Pillai's Cafe, we believe in creating more than just meals; we craft experiences that linger in your memory long after the last bite.</p>
          <p>At Pillai's Cafe, our menu is a testament to our dedication to quality and authenticity. We source the freshest ingredients and handpick spices to ensure that every dish bursts with flavor and delights the senses. Whether you're craving the comforting familiarity of a Masala Dosa or the fiery kick of Schezwan Fried Rice, our culinary offerings cater to every palate and preference.</p>
          <button>
            <Link to="/about" className={() => `navlink`}>Know More</Link>
          </button>
        </div>
      </div>
      <div className="homeMenu">
        <h1>Our Top Rated Dishes</h1>
        <img src={undi} alt="" />
        <h3>Who are in extremely love with eco friendly system.</h3>
        <div className="dishes">
          <div className="dish">
            <img src={Rice} alt="" />
            <div>
              <h2>Rice Combo</h2>
              <p>Tomato Rice, Lemon Rice, Curd Rice Or Puliyadarai Rice </p>
              <p>Serves 1, Served with Raitha, Papad & Pickle</p>
              <button><NavLink to="/menu" className="navlink">View More</NavLink></button>
            </div>
          </div>
          <div className="dish">
            <img src={MiniB} alt="" />
            <div>
              <h2>Mini Breakfast Combo</h2>
              <p>1 Idli, 1 Vada, Mini Masala Dosa, Choice of Sakkarai Pongal / Ven Pongal & Coffee</p>
              <p>Served with Coconut Chutney & Sambhar</p>
              <button><NavLink to="/menu" className="navlink">View More</NavLink></button>
            </div>
          </div>
          <div className="dish">
            <img src={FullB} alt="" />
            <div>
              <h2>Full Breakfast Combo</h2>
              <p>2 Idli, 2 Vada, Mini Masala Dosa, Choice of Sakkarai Pongal / Ven Pongal & Coffee</p>
              <p>Served with Coconut Chutney & Sambhar</p>
              <button><NavLink to="/menu" className="navlink">View More</NavLink></button>
            </div>
          </div>

        </div>

      </div>
      <div className="reviews">
        <div className="reviews-img">
          <img src={contactImg} alt="" />
        </div>
        <div className="reviews-info">
          <h1>Reviews</h1>
          <img src={undi} alt="" />
          <h3>Best In The Kharghar</h3>
          <i className="fa-solid fa-quote-left"></i>
          <p>"Pillai's Cafe has mastered the art of blending traditional South Indian flavors with the boldness of Chinese cuisine. Their dedication to authenticity and creativity is evident in every bite. The Masala Dosa and Gobi Manchurian are my personal favorites!" </p>
          <p>- Arjun Desai, Culinary Explorer.</p>
        </div>
      </div>
      <div className="reviewsContainer">
        <div className='innerReviewBox'>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Rahul S.</h2>
            <h3>August 27,2024</h3>
            <p>Exquisite flavors, impeccable service. Pillai's Cafe is a culinary gem!</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Priya R.</h2>
            <h3>July 10,2024</h3>
            <p>From dosas to dim sums, every bite is a journey of taste.</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Sameer K.</h2>
            <h3>April 30,2024</h3>
            <p>A perfect blend of two vibrant cuisines. Highly recommended!</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
