import React from 'react'
import Logo from '../../Images/Logo.jpg';
import './Reviews.css'
import undi from '../../Images/undi.png'
import PageBanner from '../../Components/PageBanner/PageBanner';

function Reviews() {
  return (
    <>
      <PageBanner pagename={"Reviews"} />
      <div className="reviewsContainer">
        <h1>What Our Customer Say</h1>
        <img src={undi} alt="" />
        <div className='innerReviewBox'>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Rahul S.</h2>
            <h3>August 27,2024</h3>
            <p>Exquisite flavors, impeccable service. Pillai's Cafe is a culinary gem!</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Priya R.</h2>
            <h3>July 10,2024</h3>
            <p>From dosas to dim sums, every bite is a journey of taste.</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Sameer K.</h2>
            <h3>April 30,2024</h3>
            <p>A perfect blend of two vibrant cuisines. Highly recommended!</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Neha M.</h2>
            <h3>May 18,2024</h3>
            <p>The variety, the quality, the taste - Pillai's Cafe sets the standard!</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Ananya P.</h2>
            <h3>July 21,2024</h3>
            <p>Absolutely love their dosas and noodles. A must-visit in Kharghar!</p>
          </div>
          <div className="reviewsBox1">
            <img src={Logo} alt="" />
            <h2>Arjun D.</h2>
            <h3>Jun 22,2024</h3>
            <p>Pillai's Cafe never disappoints. Always a treat for the taste buds!</p>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Reviews
